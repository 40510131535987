import { Component } from '@angular/core';
import { StopwatchService } from 'src/app/shared/services/stopwatch.service';

@Component({
  selector: 'app-stopwatch',
  templateUrl: './stopwatch.component.html',
  styleUrls: ['./stopwatch.component.scss'],
})
export class StopwatchComponent {
  constructor(public stopwatchService: StopwatchService) {}
}
