import { EventEmitter, Injectable } from '@angular/core';
import { GameGridTileComponent } from 'src/app/game-grid/game-grid-tile/game-grid-tile.component';
import { StopwatchService } from './stopwatch.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class GameMotorService {
  private tilesComponentsArrayVar: GameGridTileComponent[] = [];
  private currentLevelVar = 1;
  private levelChangedVar = new EventEmitter<number>();
  private levelChangedDelayedVar = new EventEmitter<number>();
  private gameStatusVar = 0; // 0 = not started, 1 = active, 2 = inactive, 3 = finished
  private emojisVar = [
    '🍄',
    '🍑',
    '🎾',
    '⚽',
    '🎲',
    '⛱️',
    '💿',
    '💎',
    '🧸',
    '🔔',
    '🚀',
    '👑',
    '🦢',
    '🐇',
    '🌍',
    '☀️',
    '🌹',
    '🐚',
    '🥥',
    '🎗️',
    '🕯️',
    '🔑',
    '🗑️',
    '🔒',
    '♻️',
    '📢',
    '🏴‍☠️',
    '📌',
    '🪑',
    '💣',
    '👒',
    '👠',
    '🌲',
    '🏀',
    '🏆',
    '🧩',
    '💡',
    '🎈',
    '🏁',
    '🎁',
  ];
  private patterns = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
  ];
  private progressionVar = 0;
  private scoresVar: number[][] = [[], [], [], []];
  private currentScoreVar: number;
  private tilesVar: GameGridTileComponent[] = [];
  private matchedPatterns = [];
  private prevTile: GameGridTileComponent = null;
  private newLevel: number;
  private gameResetVar = new EventEmitter<any>();
  private testFinishedGame = false;
  private tileDestroySpeed: number;
  private pauseGametimeout: ReturnType<typeof setTimeout>;

  constructor(
    private utilsService: UtilsService,
    private stopwatchService: StopwatchService
  ) {
    this.gameResetVar.subscribe(() => {
      this.resetGame();
    });
    this.levelChangedVar.subscribe((level: number) => {
      this.changeLevel(level);
    });
  }

  public set tilesComponentsArray(tilesComponents: GameGridTileComponent[]) {
    this.tilesComponentsArrayVar.push(...tilesComponents);
  }

  public get currentLevel(): number {
    return this.currentLevelVar;
  }

  public get levelChanged(): EventEmitter<number> {
    return this.levelChangedVar;
  }

  public get levelChangedDelayed(): EventEmitter<number> {
    return this.levelChangedDelayedVar;
  }

  public get gameReset(): EventEmitter<any> {
    return this.gameResetVar;
  }

  public get gameStatus(): number {
    return this.gameStatusVar;
  }

  public get emojis(): string[] {
    return this.emojisVar.slice();
  }

  public get progression(): number {
    return this.progressionVar;
  }

  public get scores(): number[][] {
    return this.scoresVar;
  }

  public get currentScore(): number {
    return this.currentScoreVar;
  }

  public get tiles(): GameGridTileComponent[] {
    return this.tilesVar;
  }

  public initGrid(): void {
    switch (this.currentLevelVar) {
      case 1:
        this.tileDestroySpeed = 40;
        break;
      case 2:
        this.tileDestroySpeed = 30;
        break;
      case 3:
        this.tileDestroySpeed = 25;
        break;
      case 4:
        this.tileDestroySpeed = 15;
        break;
    }
    this.tilesVar = [];
    const tempTiles = [];
    this.tilesComponentsArrayVar = [];
    this.utilsService.shuffleArray(this.patterns);
    const patternsLevel = this.patterns.slice(0, 10 * this.currentLevel);
    for (const iterator of patternsLevel) {
      tempTiles.push(iterator);
      tempTiles.push(iterator);
    }
    let check = false;
    let suffleCounter = 0;
    while (!check) {
      check = true;
      this.utilsService.shuffleArray(tempTiles);
      let prevTileInArray = null;
      tempTiles.forEach((tile) => {
        if (prevTileInArray != null) {
          if (prevTileInArray === tile) {
            check = check && false;
          } else {
            check = check && true;
          }
        }
        prevTileInArray = tile;
      });
      suffleCounter++;
    }
    console.log('suffle l' + this.currentLevel + ':' + suffleCounter);

    this.gameStatusVar = 2;
    let indexTile = 0;
    const tilesInterval = setInterval(() => {
      if (indexTile === tempTiles.length) {
        clearInterval(tilesInterval);
        this.initGridConfig();
      } else {
        this.tiles.push(tempTiles[indexTile]);
        indexTile++;
      }
    }, this.tileDestroySpeed);
  }

  private initGridConfig(): void {
    setTimeout(() => {
      this.tilesComponentsArrayVar.forEach((tile) => {
        tile.isInit = false;
        tile.isFlipped = false;
        tile.isMatched = false;
        tile.isFinished = false;
      });
    }, 200);
    setTimeout(() => {
      this.tilesComponentsArrayVar.forEach((tile) => {
        tile.isInit = true;
      });
    }, 350);
    setTimeout(() => {
      this.gameStatusVar = 0;
    }, 1500);
    /* setTimeout(() => {
      this.finishedGameTest();
    }, 500); */
  }

  public flipTile(e: GameGridTileComponent): void {
    if (this.gameStatusVar < 2 && !this.matchedPatterns.includes(e.tileValue)) {
      if (this.pauseGametimeout) {
        clearTimeout(this.pauseGametimeout);
      }
      this.pauseGametimeout = setTimeout(() => {
        if (
          this.gameStatusVar === 1 &&
          this.stopwatchService.stopwatchStatus === 1
        ) {
          this.stopwatchService.pauseStopwatch();
        }
      }, 10000);
      e.isFlipped = true;
      this.stopwatchService.startStopwatch();
      this.gameStatusVar = 1;
      if (this.prevTile === null) {
        this.prevTile = e;
      } else {
        if (this.prevTile.tileValue === e.tileValue && this.prevTile !== e) {
          this.matchedPatterns.push(e.tileValue);
          setTimeout(() => {
            this.tilesComponentsArrayVar.forEach((tile) => {
              if (tile.tileValue === e.tileValue) {
                tile.isMatched = true;
              }
            });
          }, 320);
          this.progressionVar = Math.round(
            (this.matchedPatterns.length * 100) / (10 * this.currentLevel)
          );
        } else {
          this.gameStatusVar = 2;
          setTimeout(() => {
            this.tilesComponentsArrayVar.forEach((tile) => {
              if (!tile.isMatched) {
                tile.isFlipped = false;
              }
            });
            this.gameStatusVar = 1;
          }, 500);
        }
        this.prevTile = null;

        if (this.matchedPatterns.length === 10 * this.currentLevel) {
          setTimeout(() => {
            this.tilesComponentsArrayVar.forEach((tile) => {
              tile.isFinished = true;
            });
          }, 350);
          this.gameStatusVar = 3;
          this.stopwatchService.stopStopwatch();
          this.currentScoreVar = this.stopwatchService.getTime();
          this.scoresVar[this.currentLevel - 1].push(this.currentScoreVar);
          this.scoresVar[this.currentLevel - 1].sort((a, b) => a - b);
        }
      }
    }
  }

  private resetGame(): boolean {
    if (this.stopwatchService.stopwatchStatus === 1) {
      this.stopwatchService.stopStopwatch();
    }
    if (this.gameStatusVar === 1) {
      if (!this.utilsService.resetConfirmation()) {
        return false;
      }
    }
    this.gameStatusVar = 2;
    this.matchedPatterns = [];
    this.prevTile = null;
    this.tilesComponentsArrayVar.forEach((tile) => {
      tile.isInit = false;
      tile.isFlipped = false;
      tile.isMatched = false;
      tile.isFinished = false;
    });
    setTimeout(() => {
      let i = 1;
      const tilesInterval = setInterval(() => {
        this.tilesComponentsArrayVar[
          this.tilesComponentsArrayVar.length - i
        ].isFrontFlipped = true;
        if (i === this.tilesComponentsArrayVar.length) {
          clearInterval(tilesInterval);
          this.resetGrid();
        }
        i++;
      }, this.tileDestroySpeed);
    }, 350);
  }

  private resetGrid(): void {
    if (this.newLevel) {
      this.currentLevelVar = this.newLevel;
      this.levelChangedDelayedVar.emit(this.newLevel);
    }
    this.initGrid();
    this.stopwatchService.resetStopwatch();
    this.currentScoreVar = null;
    this.progressionVar = 0;
    this.newLevel = undefined;
  }

  private changeLevel(level: number): void {
    this.newLevel = level;
    this.resetGame();
  }

  private finishedGameTest(): void {
    if (!this.testFinishedGame) {
      this.testFinishedGame = true;
      setTimeout(() => {
        this.tilesComponentsArrayVar.forEach((tile) => {
          tile.isFlipped = true;
        });
      }, 1500);
      setTimeout(() => {
        this.tilesComponentsArrayVar.forEach((tile) => {
          tile.isMatched = true;
        });
      }, 2000);
      setTimeout(() => {
        this.tilesComponentsArrayVar.forEach((tile) => {
          tile.isFinished = true;
        });
      }, 3000);
    }
  }
}
