import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StopwatchService {
  private startTime: Date;
  private stopTime: Date;
  private stopwatchStatusVar = 0; // 0 = stopped, 1 = started, 2 = paused
  private timeout: ReturnType<typeof setTimeout>;

  constructor() {}

  public get stopwatchStatus(): number {
    return this.stopwatchStatusVar;
  }

  public getTime(): number {
    return this.startTime && this.stopTime
      ? +this.stopTime - +this.startTime
      : 0;
  }

  private timer(): void {
    if (this.stopwatchStatusVar === 1) {
      this.stopTime = new Date();
      this.timeout = setTimeout(() => {
        this.timer();
      }, 1000);
    }
  }

  public startStopwatch(): void {
    if (this.stopwatchStatusVar === 0) {
      this.startTime = new Date();
    } else if (this.stopwatchStatusVar === 2) {
      this.startTime = new Date(
        Date.now() - (+this.stopTime - +this.startTime)
      );
    }
    this.stopwatchStatusVar = 1;
    this.timer();
  }

  public stopStopwatch(): void {
    this.stopwatchStatusVar = 0;
    clearTimeout(this.timeout);
    this.stopTime = new Date();
  }

  public pauseStopwatch(): void {
    this.stopwatchStatusVar = 2;
    clearTimeout(this.timeout);
    this.stopTime = new Date();
  }

  public resetStopwatch(): void {
    this.stopwatchStatusVar = 0;
    clearTimeout(this.timeout);
    this.startTime = undefined;
    this.stopTime = undefined;
  }
}
