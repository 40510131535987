import { Component, OnInit } from '@angular/core';
import { GameMotorService } from 'src/app/shared/services/game-motor.service';

@Component({
  selector: 'app-game-grid',
  templateUrl: './game-grid.component.html',
  styleUrls: ['./game-grid.component.scss'],
})
export class GameGridComponent implements OnInit {
  public currentLevel: number;

  constructor(public gameMotorService: GameMotorService) {}

  public ngOnInit(): void {
    this.currentLevel = this.gameMotorService.currentLevel;
    this.gameMotorService.levelChangedDelayed.subscribe((level: number) => {
      this.currentLevel = level;
    });
    this.gameMotorService.initGrid();
  }
}
