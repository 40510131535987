import { Component } from '@angular/core';
import { GameMotorService } from '../shared/services/game-motor.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  constructor(public gameMotorService: GameMotorService) {}

}
