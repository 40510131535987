import { Component } from '@angular/core';
import { GameMotorService } from 'src/app/shared/services/game-motor.service';

@Component({
  selector: 'app-scores',
  templateUrl: './scores.component.html',
  styleUrls: ['./scores.component.scss'],
})
export class ScoresComponent {
  public loopScores = new Array(3);

  constructor(public gameMotorService: GameMotorService) {}
}
