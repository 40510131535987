<div class="container my-5 text-center">
  <div class="row">
    <div class="col">
      <h1 class="display-5">Jeu des pairs</h1>
      <br>
      <h2 class="h3">Niveau {{gameMotorService.currentLevel}}</h2>
      <br>
      <p class="lead">
        Trouvez les symboles pairs pour battre la grille
      </p>
    </div>
    </div>
    <div class="row justify-content-center">
      <div class="col col-md-4 d-flex justify-content-around align-items-center">
        <app-stopwatch></app-stopwatch>
        <app-progress-bar class="w-50"></app-progress-bar>
      </div>
    </div>
    <div class="row">
      <div class="col">
      <app-game-grid></app-game-grid>
      <app-game-controls></app-game-controls>
      <p class="lead winMsg" *ngIf="gameMotorService.gameStatus === 3">
        Bravo vous avez battu la grille en {{gameMotorService.currentScore| date :"HH:mm:ss":'UTC'}} !
        <br>Consultez vos meilleurs scores👇
      </p>
      <app-scores></app-scores>
    </div>
  </div>
</div>
