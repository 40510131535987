import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { GameMotorService } from 'src/app/shared/services/game-motor.service';

@Component({
  selector: 'app-game-grid-tile',
  templateUrl: './game-grid-tile.component.html',
  styleUrls: ['./game-grid-tile.component.scss'],
})
export class GameGridTileComponent implements OnInit, AfterViewInit {
  @Input() public tileValue: number;
  @Input() public tileIndex: number;
  public emojis = [];
  public isInit = false;
  public isFrontFlipped = true;
  public isFlipped = false;
  public isMatched = false;
  public isFinished = false;

  constructor(private gameMotorService: GameMotorService) {}

  public ngOnInit(): void {
    this.emojis = this.gameMotorService.emojis;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.isFrontFlipped = false;
    }, 100);
    this.gameMotorService.tilesComponentsArray = [this];
  }

  public onTileFlip(): void {
    this.gameMotorService.flipTile(this);
  }
}
