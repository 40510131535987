<h2 class="h3">Vos meilleurs scores</h2>
<div class="table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>Niveau</th>
        <th>1<sup>er</sup> 🥇</th>
        <th>2<sup>ème</sup> 🥈</th>
        <th>3<sup>ème</sup> 🥉</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let scoresLevel of gameMotorService.scores; let levelIndex = index">
        <td>{{levelIndex+1}}</td>
        <ng-container *ngFor="let score of scoresLevel.slice(0, 3)">
          <td [ngClass]="{'currentScore':score === gameMotorService.currentScore}">{{score
            | date :"HH:mm:ss":'UTC'}}</td>
          <td *ngIf="!score"></td>
        </ng-container>
        <ng-container *ngFor="let loopScore of loopScores; let scoreIndex = index">
          <td *ngIf="!scoresLevel[scoreIndex]"></td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>
