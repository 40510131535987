import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private resetConfirmationMsg =
    'Êtes-vous sûr de quitter votre partie en cours?';

  constructor() {}

  public shuffleArray(array): [] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  public resetConfirmation(msg?: string): boolean {
    if (!msg) {
      msg = this.resetConfirmationMsg;
    }
    return confirm(msg);
  }
}
