import { Component, OnInit } from '@angular/core';
import { GameMotorService } from './shared/services/game-motor.service';
import { StopwatchService } from './shared/services/stopwatch.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public gameMotorService: GameMotorService,
    private stopwatchService: StopwatchService
  ) {
    window.addEventListener('beforeunload', (e) => {
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = '';
    });
    window.addEventListener('blur', (e) => {
      if (
        this.gameMotorService.gameStatus === 1 &&
        this.stopwatchService.stopwatchStatus === 1
      ) {
        this.stopwatchService.pauseStopwatch();
      }
    });
  }

  ngOnInit(): void {}
}
