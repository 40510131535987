import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appToogleTile]',
})
export class ToogleTileDirective {
  @Input() @HostBinding('class.game-grid-tile-inner--init') isInit = false;
  @Input()
  @HostBinding('class.game-grid-tile-inner--front-flipped')
  isFrontFlipped = false;
  @Input() @HostBinding('class.game-grid-tile-inner--flipped') isFlipped =
    false;
  @Input() @HostBinding('class.game-grid-tile-inner--matched') isMatched =
    false;
  @Input() @HostBinding('class.game-grid-tile-inner--finished') isFinished =
    false;

  constructor() {}
}
