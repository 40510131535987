import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { GameGridComponent } from './game-grid/game-grid/game-grid.component';
import { GameControlsComponent } from './game-controls/game-controls/game-controls.component';
import { StopwatchComponent } from './stopwatch/stopwatch/stopwatch.component';
import { ScoresComponent } from './scores/scores/scores.component';
import { ToogleTileDirective } from './shared/directives/toogle-tile.directive';
import { GameGridTileComponent } from './game-grid/game-grid-tile/game-grid-tile.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';

@NgModule({
  declarations: [
    AppComponent,
    GameGridComponent,
    GameControlsComponent,
    StopwatchComponent,
    ScoresComponent,
    ToogleTileDirective,
    GameGridTileComponent,
    ProgressBarComponent,
  ],
  imports: [BrowserModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
